<script>
import router from "@/router";
import NavBar from "@/components/structure/nav-bar";
import SideBar from "@/components/structure/side-bar";
import Footer from "@/components/structure/footer";

/**
 * Vertical layout
 *
 */
export default {
  components: { NavBar, SideBar, Footer },
  name:"Vertical",
  data() {
    return {
      isMenuCondensed: false,


    };
  },
  created() {

    document.body.removeAttribute("data-topbar", "dark");
    document.body.removeAttribute("data-layout-size", "boxed");

  },

  methods: {

    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
         if(this.isMenuCondensed) document.body.classList.remove("vertical-collpsed");
         else document.body.classList.add("vertical-collpsed");
        });
        document.body.classList.toggle("vertical-collpsed");
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
        document.body.classList.remove("vertical-collpsed");
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
  },

};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />
      <SideBar  :is-condensed="isMenuCondensed" type="icon" width="fluid"/>
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->
      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <router-view :key="$route.path"/>

          </div>
          <Footer />

        </div>

      </div>
    </div>

  </div>

</template>
