<template>
    <b-modal id="modal-bug" centered :title="$t('report.title_plateforme')" ref="modal"  title-class="font-18" hide-footer>

            <div class="row">

                <div class="col-xl-11 m-auto font-size-14">
                    <form id="form"  method="POST" @submit.prevent="handleSubmit">
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('report.type_plateforme')}} :</label>
                        <b-form-select v-model="item.type" :options="report_type">


                        </b-form-select>

                    </div>
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('report.desc')}} :</label>
                        <b-form-textarea rows="6" v-model="item.description"
                                         :class="{ 'is-invalid': typesubmit && $v.item.description.$error }"></b-form-textarea>
                        <div v-if="typesubmit && $v.item.description.$error" class="invalid-feedback">
                            <span v-if="!$v.item.description.required"> {{$t('validation.required')}}</span>
                        </div>
                    </div>
                    <input type="submit" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
                    </form>
                </div>


            </div>

    </b-modal>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import {required} from "vuelidate/lib/validators";
    export default {
        name: "reportPlatform",
        data(){
            return{
                typesubmit: false,
                report_type:[
                    {
                        value: 0,
                        text:  "Share Feedback"
                    },
                    {
                        value: 1,
                        text:  "Share Ideas"
                    },
                    {
                        value: 2,
                        text:  "Functionnal Bug"
                    },
                    {
                        value: 3,
                        text:  "Security B**"
                    },
                    {
                        value: 4,
                        text:  "Challenge Bug"
                    },
                    {
                        value: 5,
                        text:  "Content Bug"
                    },
            ],
                item:{
                    description:'',
                    type:0,
                }

            }
        },
        validations: {
            item:{
                description: {required},
            },
        },
        methods:{
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.Report();

            },
            async Report(){
                let re= await helper.postReturnData('reports',this.item)
                if(re)   this.$refs['modal'].hide()

            }
        }
    }
</script>

<style scoped>

</style>