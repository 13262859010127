<script>


import { menuItems } from "../../assets/helpers/dict";
import Collaborate from "@/components/popups/collaborate";

export default {
  components: {Collaborate},
  data() {
    return {
      menuItems: menuItems
    };
  },
  methods:{
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    }
  }


};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">

        <li :key="item.id" v-if="item.id != 10">
          <a
                  v-if="hasItems(item)"
                  href="javascript:void(0);"
                  class="is-parent"
                  :class="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
          >
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
                    :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                    v-if="item.badge"
            >{{ $t(item.badge.text)}}</span>
          </a>
          <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge badge-pill badge-${item.badge.variant} float-right`"
              v-if="item.badge"
            >{{$t(item.badge.text)}}</span>
          </router-link>
          <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                      :to="subitem.link"
                      class="side-nav-link-ref"
              >
                <i :class="`bx ${subitem.icon}`" v-if="subitem.icon"></i>
               <span>{{ $t(subitem.label) }}</span>
              </router-link>
            </li>
          </ul>


        </li>
        <li :key="item.id" v-if="item.id == 10">

          <a href="#" v-b-modal.modal-collaborate>
            <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
                    :class="`badge badge-pill badge-${item.badge.variant} float-right`"
                    v-if="item.badge"
            >{{$t(item.badge.text)}}</span>
          </a>



        </li>

      </template>
    </ul>
    <collaborate/>
  </div>
  <!-- Sidebar -->
</template>
<style scoped>
  .side-nav-link-ref.router-link-exact-active.router-link-active span,
  .side-nav-link-ref.router-link-exact-active.router-link-active i{
    color: #556ee6!important;
  }

</style>
