export const menuItems = [

    {
        id: 1,
        label: "menuitems.dashboard.text",
        link: "/dashboard",
        icon: "bx-home-circle",

    },

    {
        id: 2,
        label: "menuitems.training.text",
        icon: "bx-receipt",
        link: "/training"
    },
    {
        id: 3,
        label: "menuitems.competitions.text",
        icon: "bxs-flag",
        link: "/competitions"
    },
    {
        id: 4,
        label: "menuitems.king_of_the_hills.text",
        icon: "bxs-hot",
        link: "/koth",

    },
    {
        id: 5,
        label: "menuitems.teams.text",
        icon: "bx-group",
        link: "/teams"
    },
    {
        id: 6,
        label: "menuitems.players.text",
        icon: "bxs-user",
        link: "/users"
    },
    {
        id: 7,
        label: "menuitems.bounty.text",
        icon: "bx-bug",
        link: "/bounty"
    },

    {
        id: 8,
        label: "menuitems.streaming.text",
        icon: "bxs-video-recording",
        link: "/streaming"
    },
    // {
    //     id: 8,
    //     label: "menuitems.calendar.text",
    //     icon: "bx-calendar",
    //     link: "/calendar"
    // },
    // {
    //     id: 9,
    //     label: "menuitems.hall_of_fame.text",
    //     icon: "bx-badge",
    //     link: "/hall_of_fame"
    // },
    {
        id: 10,
        label: "menuitems.collaborate.text",
        icon: "bxs-paper-plane",
        link: ""
    },
    {
        id: 11,
        label: "menuitems.new",
        icon: "bx-gift",
        // badge: {
        //     variant: "success",
        //     text: "menuitems.king_of_the_hills.badge"
        // },
        subItems: [
            {
                id: 12,
                label: "menuitems.companies.text",
                icon: "bx bx-buildings",
                link: "/companies"
            },
            {
                id: 13,
                label: "menuitems.careers.text",
                icon: "bx bx-list-ul",
                link: "/careers"
            },

            {
                id: 14,
                label: "menuitems.universities.text",
                icon: "bx bxs-component",
                link: "/universities",
            },

            {
                id: 15,
                label: "menuitems.games.text",
                icon: "bx-cube-alt",
                link: "/games",
            },
            {
                id: 16,
                label: "menuitems.machines.text",
                icon: "bx-card",
                link: "/machines",
            }


        ]
    },




];

