<template>
    <b-modal id="modal-share-badge" centered title="" title-class="font-18" hide-footer hide-header>
        <div class="row ">
            <div class="col-xl-9 m-auto text-center ">
                <h4 class="mt-4 mb-3"> {{$t('share.congr')}}</h4>
              <p class="text-dark mb-3">{{$t('share.badge')}} <strong>{{badge.title}}</strong></p>
                <img :src="badge.avatar" class="img avatar-xl rounded-circle mb-4"/>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 m-auto">
                <h5 class="text-center mb-2"> {{$t('share.with')}}</h5>
                <div class="m-auto text-center">
                    <ShareNetwork class="btn mr-3 mb-2 text-white"
                                  v-for="network in networks"
                                  :network="network.network"
                                  :key="network.network"
                                  :style="{backgroundColor: network.color}"
                                  :url="sharing.url"
                                  :title="sharing.title"
                                  :description="sharing.description"
                                   :quote="sharing.quote"
                                  :hashtags="sharing.hashtags"
                                  :twitterUser="sharing.twitterUser"
                                >
                        <i :class="network.icon"></i>
                        <span class="ml-3">{{ network.name }}</span>
                    </ShareNetwork>

                </div>
            </div>
        </div>




    </b-modal>
</template>

<script>
    export default {
        name: "shareBadge",
        data(){
            return{
                sharing: {
                    url: this.$url_front+'/users/'+this.$store.state.user.user_id+'/badges/'+this.$store.state.badge.id,
                    title: 'I got my badge of '+this.$store.state.badge.title+' in CRISIS, check it now here \n '+this.$url_front+'/users/'+this.$store.state.user.user_id+'/badges/'+this.$store.state.badge.id,
                    description:'I got my badge of '+this.$store.state.badge.title+' in CRISIS, check it now here \n '+this.$url_front+'/users/'+this.$store.state.user.user_id+'/badges/'+this.$store.state.badge.id,
                    quote:'I got my badge of '+this.$store.state.badge.title+' in CRISIS, check it now here \n '+this.$url_front+'/users/'+this.$store.state.user.user_id+'/badges/'+this.$store.state.badge.id,
                    hashtags: 'CRISI5',
                    twitterUser: 'CRISI5'
                },
                networks: [
                    { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
                    { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
                    { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
                ]
            }

        },
      computed:{
          badge:function(){
            return this.$store.state.badge
          }
      }
    }
</script>

<style scoped>
    button{
        width: 150px;
    }
    @media screen and (max-width: 900px) {
        table tr{
            display: grid;
        }

    }

</style>