<template>
    <b-modal id="modal-collaborate" size="lg" ref="modal" centered :title="$t('clb.title')"
             title-class="font-18" hide-footer @shown="create">

        <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
            <b-tab :active="item.collaborator == 0">
                <template v-slot:title>
                    <span class=" d-sm-inline-block">{{$t('clb.creator')}}</span>
                </template>
                    <div class="row">
                        <div class="col-xl-9 m-auto font-size-14">
                            <form >
                                <div class="form-group mt-3 mb-0">
                                    <label>{{$t('clb.email')}} :</label>
                                    <b-form-input type="email" v-model="item.email" :class="{ 'is-invalid': typesubmit && $v.item.email.$error }"></b-form-input>
                                    <div v-if="typesubmit && $v.item.email.$error" class="invalid-feedback">
                                        <span v-if="!$v.item.email.required"> {{$t('validation.required')}}</span>
                                        <span v-if="!$v.item.email.email"> {{$t('validation.email')}}</span>
                                    </div>
                                </div>
                                <div class="form-group mt-3 mb-0">
                                    <label>{{$t('clb.msg')}} :</label>
                                    <b-form-textarea rows="6" v-model="item.msg" style="resize: none" :class="{ 'is-invalid': typesubmit && $v.item.msg.$error }"></b-form-textarea>
                                    <div v-if="typesubmit && $v.item.msg.$error" class="invalid-feedback">
                                        <span v-if="!$v.item.msg.required"> {{$t('validation.required')}}</span>
                                    </div>
                                </div>

                                <input type="button" @click="collaborate(0)" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
                            </form>
                        </div>


                    </div>
            </b-tab>
            <b-tab :active="item.collaborator == 1">
                <template v-slot:title>
                    <span class=" d-sm-inline-block">{{$t('clb.university')}}</span>

                </template>
                <div class="row">

                    <div class="col-xl-9 m-auto font-size-14">
                        <form >
                            <div class="form-group mt-3 mb-0">
                                <label>{{$t('clb.email')}} :</label>
                                <b-form-input type="email" v-model="item.email" :class="{ 'is-invalid': typesubmit && $v.item.email.$error }"></b-form-input>
                                <div v-if="typesubmit && $v.item.email.$error" class="invalid-feedback">
                                    <span v-if="!$v.item.email.required"> {{$t('validation.required')}}</span>
                                    <span v-if="!$v.item.email.email"> {{$t('validation.email')}}</span>
                                </div>
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <label>{{$t('clb.msg')}} :</label>
                                <b-form-textarea rows="6" v-model="item.msg" style="resize: none" :class="{ 'is-invalid': typesubmit && $v.item.msg.$error }"></b-form-textarea>
                                <div v-if="typesubmit && $v.item.msg.$error" class="invalid-feedback">
                                    <span v-if="!$v.item.msg.required"> {{$t('validation.required')}}</span>
                                </div>
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <label>{{$t('clb.type')}} :</label>
                                <b-form-select size="xl" :options="types_uni" v-model="item.type"></b-form-select>
                            </div>


                            <input type="button" @click="collaborate(1)" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
                        </form>
                    </div>


                </div>

            </b-tab>
            <b-tab :active="item.collaborator == 2">
                <template v-slot:title>
                    <span class=" d-sm-inline-block">{{$t('clb.company')}}</span>

                </template>
                <div class="row">

                    <div class="col-xl-9 m-auto font-size-14">
                        <form >
                            <div class="form-group mt-3 mb-0">
                                <label>{{$t('clb.email')}} :</label>
                                <b-form-input type="email" v-model="item.email" :class="{ 'is-invalid': typesubmit && $v.item.email.$error }"></b-form-input>
                                <div v-if="typesubmit && $v.item.email.$error" class="invalid-feedback">
                                    <span v-if="!$v.item.email.required"> {{$t('validation.required')}}</span>
                                    <span v-if="!$v.item.email.email"> {{$t('validation.email')}}</span>
                                </div>
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <label>{{$t('clb.msg')}} :</label>
                                <b-form-textarea rows="6" v-model="item.msg" style="resize: none" :class="{ 'is-invalid': typesubmit && $v.item.msg.$error }"></b-form-textarea>
                                <div v-if="typesubmit && $v.item.msg.$error" class="invalid-feedback">
                                    <span v-if="!$v.item.msg.required"> {{$t('validation.required')}}</span>
                                </div>
                            </div>
                            <div class="form-group mt-3 mb-0">
                                <label>{{$t('clb.type')}} :</label>
                                <b-form-select size="xl" :options="types_cpn" v-model="item.type" ></b-form-select>
                            </div>


                            <input type="button" @click="collaborate(2)" class="btn btn-primary float-right mt-3" variant="primary" :value="$t('competition.submit')"/>
                        </form>
                    </div>


                </div>

            </b-tab>

        </b-tabs>




    </b-modal>
</template>

<script>


    import helper from "@/assets/helpers/helper";
    import {required,email} from "vuelidate/lib/validators";

    export default {
        name: "Collaborate",
        data(){
            return{
                types_uni:[
                  { value: '0', text: 'partenaire' },
                  { value: '1', text: 'organise Competition' },
                ],
                types_cpn:[
                  { value: '0', text: 'partenaire' },
                  { value: '1', text: 'organise Competition' },
                  { value: '2', text: 'recrutement' },
                  ],
                typesubmit:false,
                item:{
                    collaborator:'',
                    type:'',
                    email:'',
                    msg:'',

                }
            }
        },
        validations: {
            item:{
                email: {required,email},
                msg: {required}
            },
        },

        methods:{
            create(){
                this.typesubmit = false
                this.item={
                        collaborator:0,
                        type:'',
                        email:'',
                        msg:'',
                }
                if(this.$route.name == 'Companies') this.item.collaborator = 2

            },
            async collaborate(a){
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.item.collaborator = a
                await helper.postReturnData('collaborate',  this.item)
            },


        }
    }
</script>

<style scoped>


</style>